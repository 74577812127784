import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import Navbar from "components/Navbar";
import ComposeAndView from "components/ComposeAndView";
import Fab from "@mui/material/Fab";
import Zoom from "@mui/material/Zoom";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { handleScrollUp } from "utils";
import { useContext } from "context/store";

const Layout = ({
  children,
  routePage,
  maxWidth = "1260px",
  gridBreakpoint = "768px",
  sx,
  uid,
  isCurrentUser,
  withGoUpIndicator = true,
  fabIcon,
  handleFabAction,
  closeDialog,
  bottom,
  composeAndViewProps
}) => {
  const [showBtn, setShowBtn] = useState(false);

  const {
    context: { commentIds }
  } = useContext();

  useEffect(() => {
    let handleScroll;
    if (withGoUpIndicator) {
      handleScroll = () => {
        window.scrollY > 500 ? setShowBtn(true) : setShowBtn(false);
      };
      window.addEventListener("scroll", handleScroll, false);
    }
    return () =>
      handleScroll && window.removeEventListener("scroll", handleScroll, false);
  }, [withGoUpIndicator]);
  const transitionDuration = {
    enter: 225,
    exit: 195
  };
  const fabStyle = {
    position: "fixed",
    right: "32px",
    bottom: "25px",
    minWidth: "25px",
    width: "30px",
    minHeight: "25px",
    height: "30px",
    svg: { color: "common.white", fontSize: "2.5em" }
  };

  const calcHeight = "calc(100vh - 68px)";

  return (
    <div
      style={{
        minHeight: "inherit",
        height: "inherit",
        width: "inherit",
        minWidth: "inherit"
      }}
    >
      <Box>
        <Navbar routePage={routePage} bottom={bottom} />
        <Box
          component="main"
          sx={{
            maxWidth,
            display: "block",
            width: "100%",
            position: "relative",
            minHeight: calcHeight,
            mb: bottom ? "40px" : "",
            mt: bottom ? "" : "64px",
            height: "auto",
            mx: "auto",
            px: {
              xs: 0,
              lg: 2
            },
            [`@media (min-width:${gridBreakpoint})`]: {
              display: "flex",
              alignItems: "flex-start",
              gap: 1,
              ...sx
            },
            ...sx
          }}
        >
          {children}
        </Box>
      </Box>
      <ComposeAndView
        uid={uid}
        isCurrentUser={isCurrentUser}
        close={closeDialog}
        currentViewId={commentIds[0]}
        {...composeAndViewProps}
      />
      <Zoom
        in={showBtn}
        timeout={transitionDuration}
        style={{
          transitionDelay: `${showBtn ? transitionDuration.exit : 0}ms`
        }}
      >
        <Fab
          color="primary"
          onClick={handleScrollUp}
          sx={
            fabIcon
              ? {
                  ...fabStyle,
                  bottom: "60px"
                }
              : fabStyle
          }
        >
          <KeyboardArrowUpIcon />
        </Fab>
      </Zoom>
      {fabIcon ? (
        <Zoom
          in={showBtn}
          timeout={transitionDuration}
          style={{
            transitionDelay: `${showBtn ? transitionDuration.exit : 0}ms`
          }}
        >
          <Fab color="primary" onClick={handleFabAction} sx={fabStyle}>
            {fabIcon}
          </Fab>
        </Zoom>
      ) : null}
    </div>
  );
};

Layout.propTypes = {};

export default Layout;
